import { Router } from "preact-router";
import NotFound from "../routes/404";
import AsyncRoute from "preact-async-route";
import Home from "../routes/home";
import Header from "../components/header";


const routes = [
  "thermodeck",
  "softtech",
  "vinilkap",
  "nauticotrancado",
  "nauticlean",
];

const App = () => (
  <div id="app">
    <Header />
    <Router>
      <Home path="/" />
      {routes.map((route) => (
        <AsyncRoute
          path={`/${route}`}
          getComponent={() =>
            import(`../routes/${route}`).then((module) => module.default)
          }
        />
      ))}
      <NotFound type="404" default />
    </Router>
    {/* <Footer />
    <WhatsappButton /> */}
  </div>
);

export default App;
