export const products = [
    {
      title: "O melhor piso náutico do mundo",
      subtitle: "Thermo Deck",
      description: "O piso THERMO DECK é uma verdadeira evolução em revestimentos náuticos e precisa fazer parte da sua experiência de navegação.",
      button: "Saiba Mais",
      image: "assets/images/banner1.jpg",
      active:"active",
      product: "assets/images/thermodeck_banner-1.jpg",
      link: "/thermodeck",
    },
    {
      title: "Piso de EVA",
      subtitle: "Soft Teck",
      description: "Conforto e design.",
      button: "Saiba Mais",
      image: "assets/images/banner1.jpg",
      product: "assets/images/softech_banner-0.jpg",
      link: "/softtech",
    },
    {
      title: "Carpete Náutico",
      subtitle: "Trançado",
      description: "Sofisticação, maciez e elegância.",
      button: "Saiba Mais",
      image: "assets/images/banner1.jpg",
      product: "assets/images/trancado_banner-2.jpg",
      link: "/nauticotrancado",
    },
    {
      title: "Tapete Vinílico",
      subtitle: "Vinil Kap",
      description: "Conforto e beleza para embarcações.",
      button: "Saiba Mais",
      image: "assets/images/banner1.jpg",
      product: "assets/images/vinilkap_banner-0.jpg",
      link: "/vinilkap",
    },
    {
      title: "Tapete Náutico",
      subtitle: "Nauti Clean",
      description: "Áreas internas com imagens sofisticadas e personalizações.",
      button: "Saiba Mais",
      image: "assets/images/banner1.jpg",
      product: "assets/images/nauticlean_banner-1.jpg",
      link: "/nauticlean",
    },
  ]


export const contatos = {
    telrj: "(24) 99864-8221",
    numerorj: "+5524998648221",
    whatsapprj: "https://wa.me/5524998648221?text=Ol%C3%A1!%20Estou%20no%20site%20da%20Kapazi%20N%C3%A1utica%20Por%20A%C3%AD%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es.",
    telsp: "(11) 94177-9925",
    numerosp: "+5511941779925",
    whatsappsp: "https://wa.me/5511941779925?text=Ol%C3%A1!%20Estou%20no%20site%20da%20Kapazi%20N%C3%A1utica%20Por%20A%C3%AD%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es.",
    instagram: "https://www.instagram.com/kapazinauticaporai",
    email: "kapaziporai@gmail.com",
    endereco: "AV ENGENHEIRO WINSTON MARUCA, 10 - LJ 14 - ANGRA DOS REIS - RJ",
    maps: "https://goo.gl/maps/TBp7nLQMBkexgtYD9"
}
